.title {
  text-align: 'left';
  color: #1e6cbb;
  font-weight: bold;
  font-size: '0.85em';
  transition: inset 0.3s 0.3s ease-out;
}
.content {
  font-family: 'Varela';
  color: #1e6cbb;
  transition: inset 0.3s 0.3s ease-out;
}

.card {
  background-color: #f5f9fc;
  height: '100%';
  cursor: default;

  @media only screen and (min-width: 62em) {
    &:hover {
      background-color: #1e6cbb9b;
      transition-duration: 0.6s;
    }
    &:hover .title {
      inset: auto auto 180px 30px;
      transition: inset 0.3s ease-out;
      color: #ffffff;
    }
    &:hover .content {
      opacity: 1;
      inset: auto auto 50px 30px;
      transition: opacity 0.5s 0.6s ease-in;
      color: #ffffff;
    }
  }
}
.card-background-blue {
  height: 100%;
  background-color: #1e6cbb8c;
}

.container-scroll-down {
  cursor: pointer;
}
