.App {
  position: relative;
  height: 100dvh;
  width: 100%;
}
.background-gadient {
  background: #ffffff;
  background: -webkit-linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
